import React, { useState } from "react"
import style from "./map.module.scss"
import StockistQuery from "../../static/queries/allStockists"
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps"

const Map = ({ setFocusedLocation, focusedLocationIndex }) => {
  const [showInfo, setShowInfo] = useState("")

  const stockists = StockistQuery()

  console.log(focusedLocationIndex)

  const focusedLocation = stockists[focusedLocationIndex]

  // Setting the default map settings
  const defaultMapSettings = {
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  }

  // Setting the maps center
  const center = {
    lat: focusedLocation.coordinates.lat || 2,
    lng: focusedLocation.coordinates.lng || 2,
  }

  return (
    <GoogleMap defaultZoom={10} center={center} options={defaultMapSettings}>
      {stockists.map((location, index) => (
        <Marker
          title={location.name}
          key={`stockist${index}`}
          name={location.name}
          position={{
            lat: location.coordinates.lat,
            lng: location.coordinates.lng,
          }}
          onClick={() => setFocusedLocation(location)}
        />
      ))}
      {/* Show info window if location is set and information is toggled on */}
      {showInfo && focusedLocation && (
        <InfoWindow
          position={{
            lat: focusedLocation.coordinates.latitude,
            lng: focusedLocation.coordinates.longitude,
          }}
          onCloseClick={() => setShowInfo(false)}
        >
          <div className="info-window"></div>
        </InfoWindow>
      )}
    </GoogleMap>
  )
}

const MapHOC = withScriptjs(withGoogleMap(Map))

export default ({
  focusedLocation,
  setFocusedLocation,
  focusedLocationIndex,
}) => {
  console.log(focusedLocationIndex)

  return (
    <MapHOC
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCsbS9XTRRsg2l5IpLgVXBEbzFUJJyK690&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: "100%" }} />}
      containerElement={<div style={{ height: "100%" }} />}
      mapElement={<div style={{ height: "100%" }} />}
      // focusedLocation={focusedLocation}
      setFocusedLocation={setFocusedLocation}
      focusedLocationIndex={focusedLocationIndex}
    />
  )
}

import React from "react"
import style from "./twoColumns.module.scss"

export default ({ children, title, body }) => (
  <div className={style.container}>
    <div className={style.left}>
      <h1>{title}</h1>
      <p>{body}</p>
    </div>
    <div className={style.right}>{children}</div>
  </div>
)

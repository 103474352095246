import React from "react"
import ButtonLink from "../buttonLink"
import style from "./gridLink.module.scss"

export default ({ caption, linkText, linkUrl }) => (
  <div className={style.container}>
    <span>{caption}</span>
    <ButtonLink text={linkText} url={linkUrl} />
  </div>
)

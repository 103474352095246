import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      allSanityStockist {
        nodes {
          city
          name
          postcode
          region
          street_and_number
          town
          coordinates {
            lat
            lng
          }
          phone
          website
        }
      }
    }
  `)

  const stockists = data.allSanityStockist.nodes

  stockists.forEach((stockist, index) => {
    stockist.latitude = stockist.coordinates.lat
    stockist.longitude = stockist.coordinates.lng
    stockist.index = index
  })

  return stockists
}

import React, { useState, useEffect } from "react"
import Layout from "../../components/layoutNew"
import style from "./stockists.module.scss"
import { graphql } from "gatsby"
import Map from "../../components/utilities/map"
import { orderByDistance } from "geolib"
import GridLink from "../../components/utilities/gridLink"
import TwoColumns from "../../components/layoutNew/innerLayouts/twoColumns"
import StockistQuery from "../../components/static/queries/allStockists"

export const data = graphql`
  {
    allSanityStockist {
      nodes {
        city
        name
        postcode
        region
        street_and_number
        town
        coordinates {
          lat
          lng
        }
      }
    }
  }
`

export default ({ data }) => {
  const [postcode, setPostcode] = useState("")
  let stockists = StockistQuery()

  console.log("stockists :>> ", stockists)
  const [focusedLocationIndex, setFocusedLocationIndex] = useState("hello")
  const focusedLocation = stockists[focusedLocationIndex]
  const [closestLocations, setClosestLocations] = useState("")
  const [userLocation, setUserLocation] = useState("")

  console.log("focusedLocationIndex :>> ", closestLocations)

  const updateClosest = current => {
    const fiveClosest = orderByDistance(
      {
        latitude: current.latitude,
        longitude: current.longitude,
      },
      stockists
    ).slice(0, 5)
    const closestIndexList = fiveClosest.map(node => node.index)
    setClosestLocations(closestIndexList)
  }

  const markerClickHandler = targetStockist => {
    updateClosest(targetStockist)
    setFocusedLocationIndex(targetStockist.index)
  }

  /* useEffect(() => {
    if (userLocation) {
      updateClosest(userLocation.coordinates)
    }
  }, [userLocation])

  useEffect(() => {
    setFocusedLocationIndex(closestLocations[0])
  }, [closestLocations])
 */
  return (
    <Layout>
      <TwoColumns
        title="Stockists"
        body="Find your nearest Phresh stockist below."
      >
        <div className={style.rightSide}>
          <div className={style.map} id="map">
            <Map
              locations={stockists}
              // focusedLocation={stockists[0]}
              setFocusedLocation={markerClickHandler}
              focusedLocationIndex={focusedLocationIndex}
            />
          </div>
          <div className={style.stockistsArea}>
            <Search
              postcode={postcode}
              setPostcode={setPostcode}
              setUserLocation={setUserLocation}
            />

            <div className={style.inner}>
              {/* <Stockists
                stockists={stockists}
                closestIndexList={closestLocations}
                setFocusedLocationIndex={setFocusedLocationIndex}
                focusedLocation={focusedLocation}
              /> */}
            </div>
          </div>

          <GridLink
            caption="Do you want to become a stockist? Get in touch with our
              distributor in your region."
            linkText="Find a distributor"
            linkUrl="/distributors"
          />
        </div>
      </TwoColumns>
    </Layout>
  )
}

const Search = ({ postcode, setPostcode, setUserLocation }) => {
  const postcodeToLatLong = () => {
    fetch(`https://api.postcodes.io/postcodes/${postcode}`)
      .then(response => response.json())
      .then(data => {
        const userLocation = {
          coordinates: {
            latitude: data.result.latitude,
            longitude: data.result.longitude,
          },
          postcode: postcode,
        }
        setUserLocation(userLocation)
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    postcodeToLatLong()
  }
  return (
    <form className={style.search} onSubmit={handleSubmit}>
      <label>
        <span>Enter your post code:</span>
        <input
          name="search"
          value={postcode}
          placeholder="e.g. SW1A 2AA"
          onChange={event => setPostcode(event.currentTarget.value)}
        />
      </label>
      <button onClick={postcodeToLatLong}>Get Results</button>
    </form>
  )
}

export const Stockists = ({
  stockists,
  setFocusedLocationIndex,
  focusedLocation,
  closestIndexList,
}) => {
  if (closestIndexList) {
    const locations = closestIndexList.map(index => stockists[index])

    return (
      <>
        {locations.map(stockist => (
          <a
            className={`${style.stockist} ${focusedLocation.name ===
              stockist.name && style.active}`}
            href="#map"
            onClick={() => setFocusedLocationIndex(stockist.index)}
          >
            <p className={style.town}>{stockist.town}</p>
            <h2 className={style.name}>{stockist.name}</h2>
            <p className={style.address}>
              {stockist.street_and_number}, {stockist.city} {stockist.postcode}
            </p>
          </a>
        ))}
      </>
    )
  } else return ""
}

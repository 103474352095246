import React from "react"
import Background from "./background"
import "../../styles/main.scss"
import style from "./layout.module.scss"
import Header from "./header"
import Footer from "./footer"

export default ({ children }) => {
  return (
    <div className={style.container}>
      <Background />
      <Header />
      <main className={style.body}>{children}</main>
      {/* To be added later - 
      <Footer /> */}
    </div>
  )
}

import React from "react"
import Particles from "react-particles-js"
import style from "./background.module.scss"
import particleData from "./particles.json"
import { detect } from "detect-browser"

// Particle background component
export default () => {
  const browser = detect()
  const browserIsChrome = browser.name === "chrome" || browser.name === "crios"

  return (
    // Wrapper for particles
    <div className={`${style.container} ${!browserIsChrome && style.static}`}>
      {browserIsChrome && (
        <>
          {/* Blurred particles to simulate close particles of dust not in focus */}
          <div className={style.blurredParticles}>
            <Particles
              width="100%"
              height="100vh"
              params={particleData.blurred}
            />
          </div>

          {/* Normal paricles in focus */}
          <div>
            <Particles
              width="100%"
              height="100vh"
              params={particleData.normal}
            />
          </div>
        </>
      )}
    </div>
  )
}

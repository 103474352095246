import React, { useState } from "react"
import { Link } from "gatsby"
import style from "./header.module.scss"
import { useWindowSize } from "../../globals/windowSize"
import logo from "../../../assets/logo.svg"
import Nav from "./nav"

export default () => {
  const [scrollHeight, setScrollHeight] = useState(0)

  // add event listener to update scroll height on scroll
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", () => {
      setScrollHeight(window.scrollY)
    })
  }

  const scrollThresholdMet = scrollHeight > 40
  return (
    <header className={`${style.header} ${scrollThresholdMet && style.opaque}`}>
      <Link to="/" className={style.logo}>
        <img src={logo} alt="Phresh logo, link to home" />
      </Link>
      <Nav />
    </header>
  )
}

import React, { useState } from "react"
import style from "./nav.module.scss"
import { Link } from "gatsby"
import hamburger from "../../../../assets/hamburger.svg"
import exit from "../../../../assets/exit.svg"
import instagram from "../../../../assets/instagram-white.svg"
import youtube from "../../../../assets/youtube.svg"
import facebook from "../../../../assets/Facebook Logo White.svg"
import twitter from "../../../../assets/Twitter Logo White.svg"
import NewTabLink from "../../../utilities/newTabLink"

export default () => {
  // State functionality for opening sidebar menu
  const [open, setOpen] = useState(false)

  return (
    <nav className={`${style.container} ${open ? style.open : style.closed}`}>
      <button onClick={() => setOpen(!open)} className={style.menuControl}>
        <img
          src={hamburger}
          alt="Menu icon, open menu"
          className={style.hamburger}
        />
        <img src={exit} alt="Menu icon, close menu" className={style.exit} />
      </button>
      <ul className={style.nav}>
        {/* Using Gatsby links to enable navigation function */}
        <li>
          <Link to="/" activeClassName={style.active}>
            Home
          </Link>
        </li>
        <li>
          <Link activeClassName={style.active} to="/calculator">
            Airflow Calculator
          </Link>
        </li>
        <li>
          <Link activeClassName={style.active} to="/stockists">
            Stockists
          </Link>
        </li>
        <li>
          <Link activeClassName={style.active} to="/resources">
            Marketing Resources
          </Link>
        </li>
        <li>
          <Link activeClassName={style.active} to="/distributors">
            Distributors
          </Link>
        </li>
        <li className={style.socialIcons}>
          <NewTabLink link="https://www.facebook.com/HyperPhresh/">
            <img src={facebook} alt="Facebook Logo, link to facebook" />
          </NewTabLink>
          <NewTabLink link="https://twitter.com/UkPhresh">
            <img src={twitter} alt="Twitter Logo, link to twitter" />
          </NewTabLink>
          <NewTabLink link="https://www.instagram.com/phresh_uk">
            <img src={instagram} alt="Instagram Logo, link to instagram" />
          </NewTabLink>
          <NewTabLink link="https://www.youtube.com/channel/UCDTHOE83JjY3xg1tBbOPLsQ">
            <img src={youtube} alt="Youtube Logo, link to youtube" />
          </NewTabLink>
        </li>
      </ul>
    </nav>
  )
}
